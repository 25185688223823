import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@core/directives/directives.module';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { ControlErrorModule } from '../control-error/control-error.module';
import { PhoneEmailInputModule } from '../phone-email-input/phone-email-input.module';
import { DeliveryUserFormComponent } from './delivery-user-form.component';

@NgModule({
  declarations: [DeliveryUserFormComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    ControlErrorModule,
    PhoneEmailInputModule,
  ],
  exports: [DeliveryUserFormComponent],
})
export class DeliveryUserFormModule {}
