import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FabAppAdCarouselComponent } from './fabapp-ad-carousel.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [FabAppAdCarouselComponent],
  imports: [CommonModule, IonicModule],
  exports: [FabAppAdCarouselComponent],
})
export class FabAppAdCarouselModule {}
